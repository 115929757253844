import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import api from './utils/api'
import * as echarts from 'echarts';
import './utils/rem';
import './assets/element-variables.scss'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

import VuePreview from 'vue-preview';

import {
  Button, Image, Card, Tabs, TabPane, Form, FormItem, Input, Link, Upload, PageHeader, Loading, Message, MessageBox, Tag,
  Notification, Select, Option, Checkbox, Radio, CheckboxGroup, Dialog, Cascader,Descriptions,Divider,Table,TableColumn,Progress,InputNumber,Scrollbar
} from 'element-ui';

import Vant from 'vant';
import 'vant/lib/index.css';
import { Toast, Dialog  as vanDialog} from 'vant';

Vue.use(Vant);
Vue.use(Button);
Vue.use(Tag);
Vue.use(Image);
Vue.use(Card);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Input);
Vue.use(Link);
Vue.use(VueAwesomeSwiper)
Vue.use(Upload);
Vue.use(PageHeader)
Vue.use(Select)
Vue.use(Option)
Vue.use(Dialog)
Vue.use(Checkbox)
Vue.use(Radio)
Vue.use(CheckboxGroup)
Vue.use(Cascader)
Vue.use(Descriptions);
Vue.use(Divider);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Progress);
Vue.use(InputNumber);
Vue.use(Scrollbar);


Vue.prototype.$loading = Loading.service;
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;
Vue.prototype.$notify = Notification;
Vue.prototype.$message = Message;
Vue.prototype.$Toast = Toast;
Vue.prototype.$Dialog = vanDialog;

Vue.use(VuePreview);

Vue.prototype.$echarts = echarts;
Vue.prototype.$api = api;
Vue.config.productionTip = false

Vue.directive('throttle', {
  bind: (el, binding) => {
    let throttleTime = binding.value; // 防抖时间
    if (!throttleTime) { // 用户若不设置防抖时间，则默认2s
      throttleTime = 2000;
    }
    let cbFun;
    el.addEventListener('click', event => {
      if (!cbFun) { // 第一次执行
        cbFun = setTimeout(() => {
          cbFun = null;
        }, throttleTime);
      } else {
        event && event.stopImmediatePropagation();
      }
    }, true);
  },
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
