import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: '',
    name: '',
    username: '',
  },
  mutations: {
    SET_TOKEN: (state, data) => {
      state.token = data
    },
    SET_NAME: (state, data) => {
      state.name = data
    },
    SET_USERNAME: (state, data) => {
      state.username = data
    },
  },
  actions: {},
  modules: {}
})